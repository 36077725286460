#editable-div-container {
 /* handled with 'styled.' */
}

#editable-div-container:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #C7D2FE;
  border-color: #6366F1;
}

#editable-div-container:hover {
  border-color: #6366F1;
}

.note-body-editor span.note-content-tag {
  display: inline-block;
  border-radius: 4px;
  padding: 0 4px;
  margin: 0 2px;
  background-color: rgba(200, 200, 200, 0.2);
  border: 1px solid rgba(200, 200, 200, 0.4);
  transition: all 0.2s ease;
}

.note-body-editor span.note-content-tag.hovered-note-content {
  background-color: rgba(66, 135, 245, 0.15);
  border-color: rgba(66, 135, 245, 0.4);
  box-shadow: 0 0 0 2px rgba(66, 135, 245, 0.1);
}

.note-body-editor span.note-content-tag.current-note-content {
  background-color: rgba(66, 135, 245, 0.25);
  border-color: rgba(66, 135, 245, 0.6);
  box-shadow: 0 0 0 2px rgba(66, 135, 245, 0.2);
}

.note-content-input {
  margin: 5px;
  width: calc(100% - 50px) !important;
}

input.note-content-input {
  border: 1px solid #ced4da;
  border-radius: 6px;
  padding: .75rem;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #495057;
}

.current-note-content {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #C7D2FE;
  border-color: #6366F1;
}

.hovered-note-content {
  border-color: #6366F1;
}

.note-content-add-buttons-container {
  width: 100%;
  overflow-x: hidden;
}

.note-content-add-buttons {
  overflow: hidden;
}

.note-content-add-buttons.shown {
  width: 80%;
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out;
}

.note-content-add-buttons.hidden {
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out;
}

.add-nc-button.rotate-90 > svg {
  transform: rotate(90deg);
  transition: transform 0.5s ease-in-out;
}

.popup-content {
  z-index: 1102 !important;
}
